import React from "react";
import { useState } from "react";
import $ from "jquery";
import jwt_decode from "jwt-decode";
import sample from "../../assets/video/sample.mp4";
import { fontWeight } from "@material-ui/system";
import { CircularProgress } from "@material-ui/core";
import logoDark from "../../assets/images/hyosung.png";

const Login = () => {
  const [user, setUser] = useState();
  const [valid, setValid] = useState(false);
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    $.ajax({
      type: "POST",
      url: "https://api.hyosungjobs.com/api/Users/Login",
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify({
        ...user,
      }),
      success: function (result) {
        localStorage.setItem("token", result.token);

        window.location.replace("/dashboard/analytics");
        const getToken = localStorage.getItem("token", result.token);
        const decoded = jwt_decode(getToken);
 
        localStorage.setItem("userName", decoded.UserName);
        localStorage.setItem("firstName", decoded.FirstName);

      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      error: function () {
        console.log("error");
        setValid(true);
      },
    });

    e.preventDefault();
  };

  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="card">
            <div className="row align-items-center text-center">
              <div className="col-md-12">
                <div className="card-body">
                  <img src={logoDark} alt="" className="img-fluid mb-4" />
                  <h2
                    className="text-center text-primary fw-bold mx-3 mb-0"
                    style={{ fontFamily: "quadrat", fontWeight: "bold" }}
                  >
                    LOGIN
                  </h2>

                  <div className="loading" id="loginLoading">
                    {" "}
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="divider d-flex align-items-center my-4"></div>
                    {/* Email input */}
                    <div className=" mb-4 input-border ">
                      <input
                        type="text"
                        onChange={handleChange}
                        value={user && user.userName}
                        name="userName"
                        className="form-control form-control-lg"
                        placeholder="User name"
                      />
                    </div>
                    {/* Password input */}
                    <div className="mb-3 input-border">
                      <input
                        type="password"
                        id="form3Example4"
                        className="form-control form-control-lg"
                        placeholder="Enter password"
                        onChange={handleChange}
                        value={user && user.password}
                        name="password"
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      {/* Checkbox */}
                    </div>
                    {valid && valid === true ? (
                      <div className="text-danger">
                        Username or password wrong
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="text-lg-start mt-4 pt-2">
                      <button
                        type="submit"
                        className="btn-primary btn-lg rounded"
                        style={{
                          paddingLeft: "2.5rem",
                          paddingRight: "2.5rem",
                        }}
                      >
                        Login
                      </button>
                      {/* <p className="small fw-bold mt-2 pt-1 mb-0">
                    Don't have an account?{" "}
                    <a href="#!" className="link-danger">
                      Register
                    </a>
                  </p> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
